import React from "react";
import { Box, ButtonBase, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const navs = [
  {
    title: "Account settings",
    description: "Profile details",
    link: "settings",
  },
  {
    title: "Notifications",
    description: "Recieve notifications",
    link: "notifications",
  },
  {
    title: "Membership",
    description: "Upgrade account",
    link: "membership",
  },
  {
    title: "Security",
    description: "Profile logistics",
    link: "password",
  },
];

export default function AccountNav() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLink = link => {
    navigate(`/profile/${link}`);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      {navs.map((nav, index) => (
        <ButtonBase key={index} sx={{ borderRadius: "14px" }}>
          <Box
            onClick={() => handleLink(nav.link)}
            sx={{
              padding: 1.2,
              borderRadius: "14px",
              width: 240,
              display: "flex",
              flexDirection: "column",
              boxShadow: location.pathname === `/profile/${nav.link}` ? 1 : 0,
              border:
                location.pathname === `/profile/${nav.link}`
                  ? "1px solid #83d475"
                  : "1px solid rgba(0, 0, 0, 0.12)",
              background:
                location.pathname === `/profile/${nav.link}`
                  ? "#daf2d6"
                  : "none",
            }}
          >
            <Typography variant="h5" align="left">
              {nav.title}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              align="left"
              fontWeight={300}
            >
              {nav.description}
            </Typography>
          </Box>
        </ButtonBase>
      ))}
    </Box>
  );
}
