import React, { useState } from "react";
import { Avatar, Box, Card, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useSnackbar } from "../../common/components/SnackbarContext";

export default function ProfileCard({ setOpen, imageSrc }) {
  const { user } = useSelector(state => state.auth);
  const [isHovered, setIsHovered] = useState(false);
  const snackbar = useSnackbar();

  const handleOpen = () => setOpen(true);

  const copyUserIdToClipboard = () => {
    const userId = user.id;
    const tempInput = document.createElement("input");
    tempInput.value = userId;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    snackbar.success("User ID copied to clipboard!");
  };

  return (
    <Card sx={{ width: 310 }}>
      <div className="upper-container">
        <div className="profilepic-container">
          <Box
            sx={{ margin: "auto" }}
            className="profilepic"
            onClick={handleOpen}
          >
            {imageSrc ? (
              <img
                onClick={handleOpen}
                className="profilepic__image"
                src={imageSrc}
                style={{ maxWidth: 150, maxHeight: 150 }}
                alt="Profile"
              />
            ) : (
              <Avatar
                sx={{ width: 125, height: 125 }}
                className="profilepic__image"
              />
            )}
            <Box className="profilepic__content">
              <span className="profilepic__icon">
                <i className="fas fa-camera"></i>
              </span>
              <span className="profilepic__text">Edit Profile</span>
            </Box>
          </Box>
        </div>
      </div>
      <Box className="lower-container">
        <Box>
          <Typography component="h3" variant="h4">
            {user.username}
          </Typography>
          <Typography component="h3" fontWeight={400} variant="body2">
            Joined 2 months ago
          </Typography>
          <Typography fontWeight={300}>{user.email}</Typography>
        </Box>
        <div>
          {isHovered && (
            <Box
              component="span"
              display="inline-block"
              mr={"5px"}
              onClick={copyUserIdToClipboard}
              style={{ cursor: "pointer" }}
            >
              <i className="fa-regular fa-copy"></i>
            </Box>
          )}
          <Typography
            variant="p"
            onClick={copyUserIdToClipboard}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{ cursor: "pointer" }}
          >
            {user.id}
          </Typography>{" "}
          (Used for adminstration purposes)
        </div>
      </Box>
    </Card>
  );
}
