import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  TextField,
  Typography
} from "@mui/material";
import { Camera } from "lucide-react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../actions/user";
import { useSnackbar } from "../../common/components/SnackbarContext";

export default function ProfileSettings({ imageSrc, setOpen }) {
  const { user } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);
  const [fName, setFName] = useState(user.fName);
  const [lName, setLName] = useState(user.lName);
  const [number, setNumber] = useState(user.number || "");
  const [city, setCity] = useState(user.city || "");
  const [email, setEmail] = useState(user.email);
  const [country, setCountry] = useState(user.country || "");
  const snackbar = useSnackbar();
  const dispatch = useDispatch();

  const handleFormSubmit = e => {
    e.preventDefault();

    try {
      dispatch(
        updateProfile(user.id, {
          fName,
          lName,
          number,
          city,
          email,
          country,
        })
      );

      snackbar.success("Profile updated!");
    } catch (error) {
      snackbar.error(message);
    }
  };

  return (
    <Card variant="outlined" sx={{ boxShadow: "none" }}>
      <CardContent sx={{ padding: 4 }}>
        <Typography variant="h3" align="center" fontWeight={300}>
          Profile Settings
        </Typography>
        <Divider variant="middle" sx={{ mt: 2 }} />
        <Box sx={{ display: "flex", alignItems: "flex-start", mt: 2 }}>
          <Box
            onClick={() => setOpen(true)}
            sx={{
              width: 1 / 4,
              mt: 2,
              display: "flex",
              flexDirection: "column",
              gap: 3,
              alignItems: "center",
            }}
          >
            <Typography fontWeight={300} align="center" variant="h5">
              Edit profile picture
            </Typography>
            <Badge
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <Camera strokeWidth="1.4px" size={30} color="white" />
              }
              overlap="circular"
              color="primary"
              sx={{
                ".MuiBadge-badge": {
                  width: 40,
                  height: 40,
                  border: "2px solid white",
                  cursor: "pointer",
                  borderRadius: "50%",
                },
              }}
            >
              <Avatar src={imageSrc} sx={{ width: 130, height: 130 }} />
            </Badge>
          </Box>
          <Divider flexItem orientation="vertical" />
          <Box sx={{ width: "90%", pl: 3 }}>
            <Box sx={{ display: "flex", gap: 5 }}>
              <TextField
                fullWidth
                margin="normal"
                label="First Name"
                name="fName"
                value={fName}
                onChange={e => setFName(e.target.value)}
                variant="outlined"
              />
              <TextField
                fullWidth
                margin="normal"
                label="Last Name"
                name="lName"
                value={lName}
                onChange={e => setLName(e.target.value)}
                variant="outlined"
              />
            </Box>
            <Box sx={{ display: "flex", gap: 5 }}>
              <TextField
                fullWidth
                margin="normal"
                label="Email"
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                variant="outlined"
              />
              <TextField
                fullWidth
                margin="normal"
                label="Number"
                name="number"
                value={number}
                onChange={e => setNumber(e.target.value)}
                variant="outlined"
              />
            </Box>
            <Box sx={{ display: "flex", gap: 5 }}>
              <TextField
                fullWidth
                margin="normal"
                label="Country"
                name="country"
                value={country}
                onChange={e => setCountry(e.target.value)}
                variant="outlined"
              />
              <TextField
                fullWidth
                margin="normal"
                label="City"
                name="city"
                value={city}
                onChange={e => setCity(e.target.value)}
                variant="outlined"
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
      <Divider />
      <Box sx={{ padding: 3, float: "right" }}>
        <Button
          onClick={handleFormSubmit}
          sx={{  px: 1.8 }}
          variant="contained"
          disableElevation
        >
          Update
        </Button>
      </Box>
    </Card>
  );
}
