import { Box, Button, Card, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { BsTwitter, BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { FiArrowRight } from "react-icons/fi";
import { SiLinkedin } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import { useSpring, animated } from "react-spring";
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import AboutBackgroundImage from "./Assets/about-background-image.png";
import AboutBackground from "./Assets/about-background.png";
import ChooseMeals from "./Assets/choose-image.png";
import DeliveryMeals from "./Assets/delivery-image.png";
import BannerImage from "./Assets/home-banner-image.png";
import PickMeals from "./Assets/pick-meals-image.png";
import logo from "../images/homePicture.png";
import growX from "../images/growx-logo.png";
import courseImage from "../images/courseImage.png";

import "./Home.css";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  const workInfoData = [
    {
      image: PickMeals,
      title: "Create AI Studysets",
      text: "Easily generate study sets with AI to enhance your learning experience. Tailored content just for you.",
    },
    {
      image: ChooseMeals,
      title: "Study with Flashcards and Quizzes",
      text: "Engage with interactive flashcards and quizzes to test your knowledge and reinforce learning.",
    },
    {
      image: DeliveryMeals,
      title: "AI Generate Courses",
      text: "Let AI craft comprehensive courses for you, covering all essential topics in an organized manner.",
    },
  ];

  return (
    <Box >
      <animated.div className="home-container">
        <Navbar />
        <motion.div className="home-banner-container">
          <div className="home-bannerImage-container">
            <img src={BannerImage} alt="" style={{ marginTop: -15 }} />
          </div>
          <div className="home-text-section">
            <h1 className="primary-heading">
              Your Number One Platform to Grow
            </h1>
            <p className="primary-text">
              Join the thousands of users that are improving all aspects of
              their lives on GrowX.
            </p>
            <Button variant="contained" onClick={() => navigate("/register")}>
              Join Now <FiArrowRight />{" "}
            </Button>
          </div>
          <div className="home-image-section">
            <img src={logo} alt="logo" />
          </div>
        </motion.div>
      </animated.div>
      <div
        className="about-section-container"
        data-aos="fade-up"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="">
          <img src={courseImage} alt="" style={{}} />
        </div>
        <div className="">
          <p className="primary-subheading">Why Us</p>
          <h1 className="primary-heading">Learning Is Vital For Everyone</h1>
          <p className="primary-text">
            Our platform harnesses the power of AI to transform the way you
            learn, offering a personalized and engaging educational journey. We
            aim to make learning accessible, enjoyable, and tailored to each
            individual's unique needs and preferences.
          </p>
          <p className="primary-text">
            By integrating advanced technology, we strive to create an
            environment where knowledge is easily attainable, fostering a
            culture of continuous growth and development for learners of all
            ages and backgrounds.
          </p>
        </div>
      </div>
      <div className="work-section-wrapper" data-aos="fade-up">
        <div className="work-section-top">
          <p className="primary-subheading">Features</p>
          <h1 className="primary-heading">How It Works</h1>
          <p className="primary-text">
            GrowX provides you with a multitude of AI based features to give you
            an enjoyable and personalized experience for your learning journey.
          </p>
        </div>
        <div className="work-section-bottom">
          {workInfoData.map(data => (
            <Card
              className="work-section-info"
              key={data.title}
              sx={{ cursor: "pointer" }}
            >
              <div className="info-boxes-img-container">
                <img src={data.image} alt="" />
              </div>
              <h2>{data.title}</h2>
              <p>{data.text}</p>
            </Card>
          ))}
        </div>
      </div>
      <div className="footer-wrapper">
        <div className="footer-section-one">
          <div className="footer-logo-container">
            <img src={growX} />
          </div>
          <div className="footer-icons">
            <BsTwitter />
            <SiLinkedin />
            <BsYoutube />
            <FaFacebookF />
          </div>
        </div>
        <div className="footer-section-two">
          <div className="footer-section-columns">
            <span>Qualtiy</span>
            <span>Help</span>
            <span>Share</span>
            <span>Carrers</span>
            <span>Testimonials</span>
            <span>Work</span>
          </div>
          <div className="footer-section-columns">
            <span>244-5333-7783</span>
            <span>hello@food.com</span>
            <span>press@food.com</span>
            <span>contact@food.com</span>
          </div>
          <div className="footer-section-columns">
            <span>Terms & Conditions</span>
            <span>Privacy Policy</span>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Home;
