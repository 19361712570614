import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  TextField,
  Typography,
  linearProgressClasses,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useSelector } from "react-redux";
import LoadingDots from "../../common/components/LoadingText";
import { useSnackbar } from "../../common/components/SnackbarContext";
import UserService from "../../services/user.service";
import AccountNav from "./AccountNav";
import "./Profile.css";
import ProfileCard from "./ProfileCard";
import ProfileSettings from "./ProfileSettings";
import { canvasPreview } from "./profileImage/canvasPreview";
import { useDebounceEffect } from "./profileImage/useDebounceEffect";

const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

export default function Profile() {
  const { user } = useSelector(state => state.auth);
  const previewCanvasRef = useRef(null);
  const inputRef = useRef(null);
  const reactCropRef = useRef();
  const imgRef = useRef(null);
  const snackbar = useSnackbar();
  const [imgSrc, setImgSrc] = useState("");
  const [imageSrc, setImageSrc] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [isDropped, setIsDropped] = useState(false);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [open, setOpen] = useState(false);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const aspect = 1;

  const closeModal = () => setModalIsOpen(false);
  const openModal = () => setModalIsOpen(true);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const imageUrl = await UserService.getProfileImage(user.id);
        setImageSrc(imageUrl);
      } catch (error) {
        // console.log(error);
      }
    };

    if (!imageSrc) {
      fetchProfileImage();
    }
  }, [imageSrc, user.id]);

  const handleImageScroll = e => {
    const delta = e.deltaY;
    const step = 0.1;

    setScale(prevScale => {
      let newScale = prevScale + (delta > 0 ? -step : step);

      const minScale = 0.1;
      const maxScale = 3;

      newScale = Math.min(Math.max(newScale, minScale), maxScale);

      const decimalPlaces = 2;
      newScale = parseFloat(newScale.toFixed(decimalPlaces));

      return newScale;
    });
  };

  useEffect(() => {
    const reactCrop = reactCropRef.current;

    if (reactCrop) {
      reactCrop.onWheel = handleImageScroll;
    }

    return () => {
      if (reactCrop) {
        reactCrop.onWheel = null;
      }
    };
  }, []);

  const onDrop = async acceptedFiles => {
    setIsDropped(false);

    if (acceptedFiles.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result ? reader.result.toString() : "")
      );
      reader.readAsDataURL(acceptedFiles[0]);
    }
  };

  const dropImage = () => {
    setIsDragOver(true);
    setIsDropped(true);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".png"],
    },
    onDragEnter: dropImage,
    onDragLeave: () => setIsDragOver(false),
  });

  const onImageLoad = e => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const uploadFile = file => {
    setIsUploading(true);

    UserService.updateProfileImage(user.id, file)
      .then(() => {
        setIsUploading(false);
        handleClose();
        snackbar.success("Profile updated successfully!");
      })
      .catch(error => {
        console.error("An error occurred:", error.message);
        setIsUploading(false);
      });
  };

  const onDownloadCropClick = async () => {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !completedCrop) {
      throw new Error("Crop canvas does not exist");
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      console.error("2D context is not available.");
      return;
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );

    const blob = await offscreen.convertToBlob({
      type: "image/png",
    });

    uploadFile(blob);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  return (
    <>
      <Box sx={{ display: "flex", gap: 4 }}>
        <Box sx={{ width: 320 }}>
          <ProfileCard setOpen={setOpen} imageSrc={imageSrc} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            width: "100%",
          }}
        >
          <AccountNav />
          <ProfileSettings imageSrc={imageSrc} setOpen={setOpen} />
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose} scroll="body">
        <DialogContent>
          <div className="wrapper">
            <Typography component="header">Upload Profile image</Typography>
            {!imgSrc && (
              <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                <form className={`${isDragOver ? "drag-over" : ""}`}>
                  <label htmlFor="fileInput" className="file-input">
                    {isUploading ? (
                      <div>
                        <LoadingDots
                          sx={{ color: "primary.dark" }}
                          text="Loading"
                        />
                      </div>
                    ) : (
                      <i className="fas fa-cloud-upload-alt"></i>
                    )}
                    {isUploading ? (
                      <Box sx={{ width: "100%", mt: 2 }}>
                        <LinearProgress
                          sx={theme => ({
                            height: 6,
                            borderRadius: 3,
                            [`& .${linearProgressClasses.bar}`]: {
                              borderRadius: 5,
                              backgroundColor: theme.palette.primary.dark,
                            },
                          })}
                        />
                      </Box>
                    ) : (
                      <Typography component="p">
                        {isDragOver ? "Drop here..." : "Browse File to Upload"}
                      </Typography>
                    )}
                  </label>
                </form>
              </div>
            )}
            <Box sx={{ display: "flex", gap: 3 }}>
              <TextField
                label="Scale"
                id="scale-input"
                type="number"
                margin="none"
                inputProps={{
                  step: "0.1",
                  min: "0.1",
                }}
                value={scale}
                disabled={!imgSrc}
                onChange={e => setScale(Number(e.target.value))}
                helperText="Scrolling also changes scale"
              />
              <TextField
                label="Rotation degree"
                id="rotate-input"
                type="number"
                margin="none"
                value={rotate}
                disabled={!imgSrc}
                onChange={e =>
                  setRotate(
                    Math.min(180, Math.max(-180, Number(e.target.value)))
                  )
                }
              />
            </Box>
            {imageSrc && <Divider sx={{ mt: 3 }} />}
            {!!imgSrc && (
              <Box
                className={`image-container ${
                  isDragOver && isDropped && "drag-over"
                }`}
              >
                <Box className="image-wrapper">
                  <div
                    className="profilepic__image-container"
                    onWheel={handleImageScroll}
                    ref={reactCropRef}
                  >
                    <ReactCrop
                      crop={crop}
                      onChange={(_, percentCrop) => setCrop(percentCrop)}
                      onComplete={c => setCompletedCrop(c)}
                      circularCrop
                      aspect={aspect}
                    >
                      <img
                        ref={imgRef}
                        alt="Crop me"
                        src={imgSrc}
                        style={{
                          transform: `scale(${scale}) rotate(${rotate}deg)`,
                          cursor: "move",
                        }}
                        onLoad={onImageLoad}
                      />
                    </ReactCrop>
                  </div>
                </Box>
              </Box>
            )}
            {imgSrc && (
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  color="primary"
                  className="preview-links center"
                  onClick={openModal}
                >
                  View preview
                </Typography>
                <Typography
                  color="primary"
                  className="preview-links center"
                  onClick={() => inputRef.current.click()}
                >
                  Change image
                </Typography>
              </Box>
            )}
          </div>
          {completedCrop && (
            <canvas
              ref={previewCanvasRef}
              style={{
                border: "1px solid black",
                borderRadius: "50%",
                display: "none",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          )}
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} ref={inputRef} />
          </div>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton
              onClick={onDownloadCropClick}
              variant="contained"
              loading={isUploading}
              startIcon={<Save />}
              disabled={!imgSrc}
              loadingPosition="start"
              disableElevation
            >
              <span>Save</span>
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {completedCrop && (
        <Dialog open={modalIsOpen} onClose={closeModal}>
          <DialogTitle sx={{ p: 2 }}>Image Preview</DialogTitle>
          <DialogContent>
            <canvas
              ref={previewCanvasRef}
              style={{
                borderRadius: "50%",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
