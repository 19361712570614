import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  circularProgressClasses,
  Divider,
  Drawer,
  Grid,
  Grow,
  LinearProgress,
  linearProgressClasses,
  Skeleton,
  Typography,
} from "@mui/material";
import { PieChart } from "@mui/x-charts";
import LoadingDots from "../../common/components/LoadingText";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useSelector } from "react-redux";
import noImage from "../../images/noImage.png";
import { getAllCourses } from "../../services/course.service";
import { getAllQuizzes } from "../../services/quiz.service";
import userService from "../../services/user.service";
import AnalyticCard from "./AnalyticsCard";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const { user } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const [avatar, setAvatar] = useState(null);
  const [clickData, setClickData] = useState(null);
  const [cardHeight, setCardHeight] = useState("auto");
  const [analytics, setAnalytics] = useState({
    inProgress: 0,
    completed: 0,
    totalQuizzesTaken: 0,
    toStart: 0,
  });
  const pieChartRef = useRef(null);
  const navigate = useNavigate();

  const calculateCourseProgress = useCallback(
    course => {
      const userProgress = course.userProgress.find(
        progress => progress.userId === user.id
      );

      if (!userProgress) return 0;

      const totalTopics = course.chapters.reduce(
        (sum, chapter) => sum + chapter.topics.length,
        0
      );
      const completedTopics = userProgress.chapters.reduce(
        (sum, chapter) =>
          sum + chapter.topics.filter(topic => topic.completed).length,
        0
      );

      return Math.round((completedTopics / totalTopics) * 100);
    },
    [user.id]
  );

  const calculateAnalytics = useCallback(
    (courses, totalQuizzesTaken) => {
      let inProgress = 0;
      let completed = 0;
      let toStart = 0;

      courses.forEach(course => {
        const progress = calculateCourseProgress(course);
        if (progress === 100) {
          completed++;
        } else if (progress > 0) {
          inProgress++;
        } else {
          toStart++;
        }
      });

      setAnalytics({
        inProgress,
        completed,
        totalQuizzesTaken,
        toStart,
      });
    },
    [calculateCourseProgress]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const fetchedCourses = await getAllCourses();
        const fetchedQuizzes = await getAllQuizzes();

        const enrolledCourses = fetchedCourses.courses.filter(course =>
          course.enrolledUsers.includes(user.id)
        );

        const userQuizzes = fetchedQuizzes.quizzes.filter(quiz =>
          quiz.userProgress.some(
            progress => progress.userId.toString() === user.id
          )
        );

        const totalQuizzesTaken = userQuizzes.length;
        const quizScores = userQuizzes.map(quiz => {
          const userProgress = quiz.userProgress.find(
            progress => progress.userId.toString() === user.id
          );
          return (userProgress.score / quiz.questions.length) * 100;
        });

        const averageQuizScore =
          quizScores.length > 0
            ? quizScores.reduce((sum, score) => sum + score, 0) /
              quizScores.length
            : 0;

        setCourses(enrolledCourses);
        setQuizzes(userQuizzes);

        calculateAnalytics(enrolledCourses, totalQuizzesTaken);
        setAnalytics(prevAnalytics => ({
          ...prevAnalytics,
          averageQuizScore: Math.round(averageQuizScore),
        }));

        setLoading(false);
      } catch (error) {
        // console.log(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [user.id, calculateAnalytics]);

  useEffect(() => {
    if (pieChartRef.current) {
      setCardHeight(`${pieChartRef.current.clientHeight}px`);
    }
  }, []);

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const imageUrl = await userService.getProfileImage(user?.id);
        setAvatar(imageUrl);
      } catch (error) {
        // console.log(error);
      }
    };

    if (!avatar) {
      fetchProfileImage();
    }
  }, [avatar, user?.id]);

  const allCategories = [
    {
      id: 0,
      value: analytics.toStart,
      label: "Courses To Start",
      color: "#FF6B6B",
    },
    {
      id: 1,
      value: analytics.inProgress,
      label: "Courses In Progress",
      color: "#FFAB00",
    },
    {
      id: 2,
      value: analytics.completed,
      label: "Courses Completed",
      color: "#22C55E",
    },
    {
      id: 3,
      value: analytics.totalQuizzesTaken,
      label: "Studysets",
      color: "#8E33FF",
    },
  ];

  const chartData = allCategories.filter(item => item.value > 0);

  const total = allCategories.reduce((sum, item) => sum + item.value, 0);
  const sortedQuizzes = quizzes
    .filter(
      quiz =>
        quiz.user === user.id ||
        quiz.userProgress.some(prog => prog.userId === user.id)
    )
    .sort((a, b) => {
      const aScore =
        a.userProgress.find(prog => prog.userId === user.id)?.score || 0;
      const bScore =
        b.userProgress.find(prog => prog.userId === user.id)?.score || 0;
      const aTotal = a.questions.length;
      const bTotal = b.questions.length;
      return bScore / bTotal - aScore / aTotal;
    });

  return (
    <Box sx={{ display: "flex" }}>
      <Box component="main" sx={{ flexGrow: 1, px: 2 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ textTransform: "capitalize" }}
        >
          Hi, {user.fName} 👋
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Let's learn something new today!
        </Typography>
        <br />
        <Grid container spacing={3}>
          <Grow in>
            <Grid item xs={4}>
              <AnalyticCard
                title="Courses in Progress"
                value={loading ? <LoadingDots circle /> : analytics.inProgress}
                icon="https://pub-c5e31b5cdafb419fb247a8ac2e78df7a.r2.dev/public/assets/icons/courses/ic-courses-progress.svg"
                color="linear-gradient(135deg, #FFAB00 0%, #B76E00 100%)"
                gradientColor="#FFAB00"
              />
            </Grid>
          </Grow>
          <Grow in>
            <Grid item xs={4}>
              <AnalyticCard
                title="Courses Completed"
                value={loading ? <LoadingDots circle /> : analytics.completed}
                icon="https://pub-c5e31b5cdafb419fb247a8ac2e78df7a.r2.dev/public/assets/icons/courses/ic-courses-completed.svg"
                color="linear-gradient(135deg, #22C55E 0%, #118D57 100%)"
                gradientColor="#22C55E"
              />
            </Grid>
          </Grow>
          <Grow in>
            <Grid item xs={4}>
              <AnalyticCard
                title="Studysets"
                value={
                  loading ? <LoadingDots circle /> : analytics.totalQuizzesTaken
                }
                icon="https://pub-c5e31b5cdafb419fb247a8ac2e78df7a.r2.dev/public/assets/icons/courses/ic-courses-progress.svg"
                color="linear-gradient(135deg, #8E33FF 0%, #5119B7 100%)"
                gradientColor="#8E33FF"
              />
            </Grid>
          </Grow>

          <Grow in>
            <Grid item xs={6}>
              <Card ref={pieChartRef} sx={{ height: "auto" }}>
                <CardContent>
                  <CardHeader title="Progress" />
                  <Box
                    sx={{
                      ...(loading && {
                        padding: 4,
                        mt: 0,
                      }),
                      mt: -2,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {loading ? (
                      <LinearProgress
                        sx={{
                          width: "110%",
                          height: "10px",
                          [`&.${linearProgressClasses.colorPrimary}`]: {
                            backgroundColor: "rgba(145 158 171 / 0.16)",
                          },
                        }}
                      />
                    ) : (
                      <>
                        <Box
                          sx={{
                            width: "95%",
                            margin: "auto",
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {chartData.length > 0 ? (
                            <PieChart
                              series={[
                                {
                                  data: chartData,
                                  innerRadius: 80,
                                  outerRadius: 120,
                                  paddingAngle: 2,
                                  cornerRadius: 5,
                                  startAngle: -90,
                                  endAngle: 270,
                                  cx: 150,
                                  cy: 150,
                                  highlightScope: {
                                    faded: "global",
                                    highlighted: "item",
                                  },
                                  faded: {
                                    innerRadius: 80,
                                    outerRadius: 120,
                                    color: "gray",
                                  },
                                },
                              ]}
                              slotProps={{
                                legend: { hidden: true },
                              }}
                              onItemClick={(event, itemData, item) => {
                                setClickData(item);
                              }}
                              width={300}
                              height={300}
                            />
                          ) : (
                            <Box
                              sx={{
                                height: 300,
                              }}
                            />
                          )}
                          <Typography
                            variant="h4"
                            sx={{
                              position: "absolute",
                              left: "51.5%",
                              top: "51%",
                              transform: "translate(-50%, -50%)",
                              textAlign: "center",
                            }}
                          >
                            <Grow
                              in={true}
                              key={clickData ? clickData.label : "total"}
                            >
                              <div>
                                <Typography
                                  variant="body2"
                                  align="center"
                                  color="text.secondary"
                                  sx={{ fontWeight: 600 }}
                                >
                                  {clickData ? clickData.label : "Total"}
                                </Typography>
                                {clickData ? clickData.value : total}
                              </div>
                            </Grow>
                          </Typography>
                        </Box>
                        <Divider
                          sx={{
                            borderStyle: "dashed",
                            margin: theme => theme.spacing(1, -2, 3),
                          }}
                          flexItem
                        />
                        <Box sx={{ width: "100%", margin: "auto" }}>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "repeat(2, 1fr)",
                              gap: 2,
                              ml: 3,
                            }}
                          >
                            {allCategories.map(item => (
                              <Grow in>
                                <Box
                                  key={item.id}
                                  sx={{
                                    display: "flex",
                                    gap: "4px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: 10,
                                      height: 10,
                                      borderRadius: "50%",
                                      bgcolor: item.color,
                                      mr: 1,
                                      mt: "4px",
                                    }}
                                  />
                                  <div>
                                    <Typography>{item.label}</Typography>
                                    <Typography
                                      color="text.secondary"
                                      variant="subtitle2"
                                    >
                                      {item.value} total
                                    </Typography>
                                  </div>
                                </Box>
                              </Grow>
                            ))}
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grow>
          <Grow in>
            <Grid item xs={6}>
              <Card
                sx={{
                  height: cardHeight,
                  display: "flex",
                  flexDirection: "column",
                  pr: "6px",
                  position: "relative",
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: "80px",
                    background: "linear-gradient(to top, #28323D, transparent)",
                    pointerEvents: "none",
                  },
                }}
              >
                <CardContent
                  sx={{
                    flex: "0 0 auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <CardHeader title="Recent Studysets" />
                  <Button onClick={() => navigate("/studysets")}>
                    View all
                  </Button>
                </CardContent>
                <Scrollbars
                  thumbSize={80}
                  style={{ border: 0, outline: 0 }}
                  renderThumbVertical={({ style, ...props }) => (
                    <div
                      {...props}
                      style={{
                        ...style,
                        backgroundColor: "#39454f",
                        borderRadius: 3,
                        maxHeight: "60px",
                        outline: "none",
                        border: "none",
                      }}
                    />
                  )}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "24px",
                      flex: 1,
                      overflowY: "auto",
                      px: 4,
                      pb: 4,
                    }}
                  >
                    {loading && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                          width: "100%",
                        }}
                      >
                        {Array(10)
                          .fill(0)
                          .map((_, index) => (
                            <Box
                              sx={{
                                display: "flex",
                                gap: "16px",
                                width: "95%",
                              }}
                            >
                              <Skeleton
                                variant="rounded"
                                width={76}
                                height={56}
                                sx={{ borderRadius: 2 }}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <Skeleton />
                                <Typography sx={{ fontSize: "0.75rem" }}>
                                  <Skeleton width="65%" />
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                      </Box>
                    )}
                    {sortedQuizzes.map(quiz => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "16px",
                            width: "95%",
                          }}
                        >
                          <CardMedia
                            component="image"
                            image={quiz.imageUrl || noImage}
                            sx={{
                              minWidth: 56,
                              minHeight: 56,
                              borderRadius: 2,
                            }}
                          />

                          <Box
                            key={quiz.id}
                            sx={{
                              minWidth: "0px",
                              display: "flex",
                              flex: "1 1 auto",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              noWrap
                              onClick={() => navigate(`/studyset/${quiz._id}`)}
                              sx={{
                                margin: "0 0 4px",
                                fontSize: "0.875rem",
                                fontWeight: 600,
                                width: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                textTransform: "capitalize",
                                cursor: "pointer",
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              }}
                            >
                              {quiz.title}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ fontSize: "0.75rem" }}
                            >
                              {quiz.questions.length}{" "}
                              {quiz.questions.length === 1 ? "Term" : "Terms"}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Scrollbars>
              </Card>
            </Grid>
          </Grow>
        </Grid>
      </Box>
      <Drawer
        variant="permanent"
        anchor="right"
        sx={{
          width: 300,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 300,
            pr: "2px",
            boxSizing: "border-box",
            bgcolor: "#28323D",
            backgroundImage: "none",
            border: "none",
          },
        }}
      >
        <Scrollbars
          autoHide
          style={{ border: 0, outline: 0 }}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                backgroundColor: "#39454f",
                borderRadius: 3,
                height: "10%",
                outline: "none",
                border: "none",
              }}
            />
          )}
        >
          <Box
            sx={{
              width: "100%",
              p: theme => theme.spacing(8, 3, 2, 3),
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "96px",
                height: "96px",
                flexShrink: 0,
                borderRadius: "50%",
                position: "relative",
                alignItems: "center",
                display: "inline-flex",
                justifyContent: "center",
                mb: "16px",
              }}
            >
              <Avatar
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 auto",
                  mb: 2,
                  backgroundColor: "#00A76F",
                  color: "white",
                  fontWeight: 800,
                  fontSize: "2rem",
                  textTransform: "uppercase",
                }}
              >
                {user?.fName[0] + user?.lName[0]}
              </Avatar>
              <Box
                sx={{
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  borderRadius: "inherit",
                  background:
                    "linear-gradient(135deg, 0 167 111 25%, #00A76F 100%)",
                  mask: "linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px) content-box xor, linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px)",
                  padding: "2px",
                }}
              />
            </Box>
            <Typography
              variant="h6"
              align="center"
              sx={{ fontWeight: 600, textTransform: "capitalize" }}
              gutterBottom
            >
              {user.fName} {user.lName}
            </Typography>
            <Typography
              align="center"
              color="#637381"
              variant="body2"
              noWrap
              sx={{ mb: 2 }}
            >
              Member since {moment(user.createdAt).format("MMM YYYY")}
            </Typography>
          </Box>
          <Box
            sx={{
              gap: "24px",
              px: 3,
              display: "flex",
              flexDirection: "column",
              mb: 5,
            }}
          >
            <div>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                Courses in progress{" "}
              </Typography>
              <Typography variant="body2" color="#637381" noWrap>
                {loading ? (
                  <LoadingDots text="Loading your courses" />
                ) : (
                  <div>
                    {
                      courses.filter(course => {
                        const progress = calculateCourseProgress(course);
                        return progress > 0 && progress < 100;
                      }).length
                    }{" "}
                    total courses
                  </div>
                )}
              </Typography>
            </div>
            {loading && (
              <Box sx={{ position: "relative" }}>
                <CircularProgress
                  variant="determinate"
                  sx={{
                    color: "rgba(145 158 171 / 0.16)",
                  }}
                  size={40}
                  thickness={4}
                  value={100}
                />
                <CircularProgress
                  variant="indeterminate"
                  color="primary"
                  disableShrink
                  sx={{
                    animationDuration: "550ms",
                    position: "absolute",
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: "round",
                    },
                  }}
                  size={40}
                  thickness={4}
                />
              </Box>
            )}
            {courses
              .filter(course => {
                const progress = calculateCourseProgress(course);
                return progress > 0 && progress < 100;
              })
              .sort(
                (a, b) =>
                  calculateCourseProgress(b) - calculateCourseProgress(a)
              )
              .map(course => (
                <Box key={course._id}>
                  <Box
                    sx={{
                      gap: "12px",
                      display: "flex",
                      color: "primary.main",
                    }}
                  >
                    <Box
                      sx={{
                        width: "6px",
                        my: "3px",
                        height: "16px",
                        flexShrink: 0,
                        opacity: 0.24,
                        borderRadius: "8px",
                        backgroundColor: "currentcolor",
                      }}
                    />
                    <Box
                      sx={{
                        gap: "8px",
                        minWidth: "0px",
                        display: "flex",
                        flex: "1 1 auto",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        noWrap
                        variant="subtitle2"
                        onClick={() => navigate(`/course/${course._id}`)}
                        sx={{
                          margin: 0,
                          fontWeight: 700,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          color: "white",
                          textTransform: "capitalize",
                          cursor: "pointer",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        {course.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "0.75rem",
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                        color="text.secondary"
                      >
                        <Icon
                          icon="solar:calendar-date-bold"
                          width="16"
                          height="16"
                        />
                        {moment(course.createdAt).format("MMM YYYY Do HH:mm a")}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <LinearProgress
                          color="primary"
                          variant="determinate"
                          sx={{
                            width: "100%",
                            height: "6px",
                            borderRadius: "4px",
                            [`&.${linearProgressClasses.colorPrimary}`]: {
                              backgroundColor: "rgba(145 158 171 / 0.16)",
                            },
                          }}
                          value={calculateCourseProgress(course)}
                        />
                        <Typography variant="body2" color="white">
                          {calculateCourseProgress(course)}%
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
        </Scrollbars>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: "80px",
            background: "linear-gradient(to top, #28323D, transparent)",
            pointerEvents: "none",
          }}
        />
      </Drawer>
    </Box>
  );
};

export default Dashboard;
