import { CheckRounded } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import { BookOpenCheck, ListRestart } from "lucide-react";
import React, { useEffect, useState } from "react";
import Flip from "react-card-flip";
import { useNavigate, useParams } from "react-router-dom";

export default function Flashcard({
  flashcard,
  flipped,
  setFlipped,
  showAnswerFirst,
  timerEnabled,
  timerDuration,
  submitted,
  correct,
  incorrect,
  numIncorrect,
  repeatIncorrect,
}) {
  const [timeLeft, setTimeLeft] = useState(timerDuration);
  const navigate = useNavigate();
  const { quizId } = useParams();
  useEffect(() => {
    if (showAnswerFirst) {
      setFlipped(true);
    } else {
      setFlipped(false);
    }
  }, [showAnswerFirst, setFlipped]);

  useEffect(() => {
    if (timerEnabled) {
      setTimeLeft(timerDuration);
      const timer = setInterval(() => {
        setTimeLeft(prev => (prev > 0 ? prev - 1 : 0));
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timerEnabled, timerDuration]);

  useEffect(() => {
    if (timerEnabled && timeLeft === 0) {
      setFlipped(true);
    }
  }, [timeLeft, timerEnabled, setFlipped]);

  const handleFlip = () => {
    setFlipped(!flipped);
  };

  return (
    <>
      {!submitted || (repeatIncorrect && numIncorrect > 0) ? (
        <Flip isFlipped={flipped} flipDirection="vertical">
          <Card
            sx={{
              mb: 2,
              mt: 2,
              width: "50vw",
              height: "50vh",

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                cursor: "pointer",
                bgcolor: "rgba(145 158 171 / 0.08)",
              },
            }}
            onClick={handleFlip}
          >
            <CardContent>
              <Typography align="center" variant="h5">
                {flashcard?.question}
              </Typography>
              {timerEnabled && (
                <Typography
                  align="center"
                  variant="subtitle2"
                  color="text.secondary"
                >
                  Time left: {timeLeft}s
                </Typography>
              )}
            </CardContent>
          </Card>

          <Card
            sx={{
              mb: 2,
              mt: 2,
              width: "50vw",
              height: "50vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                cursor: "pointer",
                bgcolor: "rgba(145 158 171 / 0.08)",
              },
            }}
            onClick={handleFlip}
          >
            <CardContent>
              <Typography align="center" variant="h5">
                {flashcard?.answer}
              </Typography>
            </CardContent>
          </Card>
        </Flip>
      ) : (
        <Card
          sx={{
            mb: 2,
            width: "50vw",

            mt: 2,
            display: "flex",
            p: 4,
          }}
        >
          <CardContent sx={{ width: "100%" }}>
            <Typography sx={{ marginRight: "auto" }} variant="h4">
              Flashcards Review
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                p: 2,

                borderRadius: 1,
                width: "100%",
                mt: 2,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  m: 2,
                  ml: 0,
                  mr: 3,
                }}
              >
                <CircularProgress
                  variant="determinate"
                  value={100}
                  size={80}
                  thickness={4}
                  sx={{
                    color: "rgba(145 158 171 / 0.2)",
                    position: "absolute",
                  }}
                />
                <CircularProgress
                  variant="determinate"
                  value={(correct / (incorrect + correct)) * 100}
                  size={80}
                  thickness={4}
                  sx={{ color: "#22C55E" }}
                />

                <Box
                  sx={{
                    top: "8%",
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    color="textSecondary"
                  >
                    <CheckRounded sx={{ color: "#22C55E" }} fontSize="large" />
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ width: "100%", ml: 4 }}>
                <Box
                  textAlign="center"
                  sx={{
                    display: "flex",
                    width: "100%",
                    p: 0.5,
                    px: 3,

                    backgroundColor: "rgba(34, 197, 94, 0.23)",
                    borderRadius: "13px",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",

                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6">Correct</Typography>
                  <Typography variant="h4">{correct}</Typography>
                </Box>
                <Box
                  mt={2}
                  textAlign="center"
                  sx={{
                    display: "flex",
                    width: "100%",
                    p: 0.5,
                    px: 3,
                    backgroundColor: "rgba(145 158 171 / 0.08)",
                    borderRadius: "13px",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6">Incorrect</Typography>
                  <Typography variant="h4">{incorrect}</Typography>
                </Box>
              </Box>
            </Box>
            <Divider sx={{ mx: -10, my: 3, borderStyle: "dashed" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <Paper
                sx={{
                  p: 2,
                  mt: 3,
                  borderRadius: "12px",
                  transition: "0.3s ease-in-out",
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "rgba(145 158 171 / 0.08)",
                  },
                }}
                onClick={() => window.location.reload()}
              >
                <Box
                  sx={{
                    display: "flex",

                    alignItems: "center",
                  }}
                >
                  <ListRestart style={{ width: 40, height: 40 }} />
                  <Box sx={{ ml: 3 }}>
                    <Typography variant="h6">Restart Flashcards</Typography>
                    <Typography
                      sx={{ color: "text.secondary", mt: 0.5 }}
                      variant="body1"
                    >
                      Review your flashcards again to fully master this studyset
                    </Typography>
                  </Box>
                </Box>
              </Paper>
              <Paper
                sx={{
                  p: 2,
                  mt: 2,
                  borderRadius: "12px",
                  transition: "0.3s ease-in-out",
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "rgba(145 158 171 / 0.08)",
                  },
                }}
                onClick={() => navigate(`/studyset/${quizId}`)}
              >
                <Box
                  sx={{
                    display: "flex",

                    alignItems: "center",
                  }}
                >
                  <BookOpenCheck style={{ width: 40, height: 40 }} />
                  <Box sx={{ ml: 3 }}>
                    <Typography variant="h6">View Studyset</Typography>
                    <Typography
                      sx={{ color: "text.secondary", mt: 0.5 }}
                      variant="body1"
                    >
                      View your studyset for more study methods.
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  );
}
