import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grow,
  Skeleton,
  Slide,
  Typography,
} from "@mui/material";
import {
  Check,
  ChevronLeft,
  Settings as SettingsIcon,
  XCircle,
} from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setCurrentQuiz } from "../../../actions/quiz";
import { getQuizById, updateStudies } from "../../../services/quiz.service";
import Flashcard from "./Flashcard";
import Settings from "./Settings";

export default function Flashcards() {
  const dispatch = useDispatch();
  const { currentQuiz } = useSelector(state => state.quiz);
  const { quizId } = useParams();

  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [flipped, setFlipped] = useState(false);
  const { user } = useSelector(state => state.auth);
  const [slideIn, setSlideIn] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [score, setScore] = useState(0);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [shuffle, setShuffle] = useState(false);
  const [showAnswerFirst, setShowAnswerFirst] = useState(false);
  const [timerEnabled, setTimerEnabled] = useState(false);
  const [timerDuration, setTimerDuration] = useState(30);
  const [repeatIncorrect, setRepeatIncorrect] = useState(true);
  const [incorrectQuestions, setIncorrectQuestions] = useState([]);
  const [currentIncorrectIndex, setCurrentIncorrectIndex] = useState(0);
  const containerRef = useRef(null);

  const handleSubmit = async () => {
    const userId = user.id;
    // console.log(studies);
    setSubmitted(true);
    // console.log(submitted);
  };
  useEffect(() => {
    const fetchQuiz = async () => {
      setLoading(true);

      try {
        const quizData = await getQuizById(quizId);
        dispatch(setCurrentQuiz(quizData.quiz));

        let questions = quizData.quiz.questions;

        if (shuffle) {
          questions = questions.sort(() => Math.random() - 0.5);
          dispatch(setCurrentQuiz({ ...quizData.quiz, questions }));
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching quiz:", error);
        setLoading(false);
      }
    };

    fetchQuiz();
  }, [quizId, dispatch, shuffle]);

  const handleNext = async correct => {
    setFlipped(false);
    setSlideIn(false);

    if (!correct) {
      setIncorrectQuestions(prev => [
        ...prev,
        currentQuiz.questions[currentIndex],
      ]);
    }

    setScore(prevScore => prevScore + (correct ? 1 : 0));
    setTimeout(() => {
      setCurrentIndex(prevIndex => {
        if (prevIndex === currentQuiz.questions.length - 1) {
          handleSubmit();
          return prevIndex; // Stay on the same index as it is the last question
        } else {
          return prevIndex + 1;
        }
      });
      setSlideIn(true);
    }, 350);
  };

  const handleRepeatSubmit = () => {
    setSubmitted(true);
    setRepeatIncorrect(false);
    setIncorrectQuestions([]);
    setSlideIn(true);
    // console.log(submitted);
    setTimeout(() => {
      // console.log(incorrectQuestions);
      // console.log(repeatIncorrect);
    }, 6000);
  };

  useEffect(() => {
    // console.log(repeatIncorrect, submitted);
  }, [repeatIncorrect, submitted]);
  const handleRepeatNext = () => {
    setFlipped(false);
    setSlideIn(false);
    {
      currentIncorrectIndex === incorrectQuestions.length - 1
        ? (() => {
            setSubmitted(false);
            setIncorrectQuestions([]);
          })()
        : setTimeout(() => {
            setCurrentIncorrectIndex(prevIndex => prevIndex + 1);
            setSlideIn(true);
          }, 350);
    }
  };

  return (
    <>
      <Box>
        <Link to={`/studyset/${quizId}`}>
          <Button variant="outlined" startIcon={<ChevronLeft />}>
            Back
          </Button>
        </Link>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Box
            sx={{
              width: "100%",
              padding: "10px",
            }}
          >
            {loading ? (
              <div>
                <Typography variant="h4">
                  <Skeleton />
                </Typography>
              </div>
            ) : (
              <div>
                <Typography id="text" variant="h4" sx={{ mb: 1 }}>
                  Reviewing{" "}
                  <strong
                    style={{
                      textTransform: "capitalize",
                      textDecoration: "underline",
                    }}
                  >
                    {currentQuiz.title}
                  </strong>{" "}
                  with Flashcards
                </Typography>
                <Typography color="text.secondary">
                  {currentQuiz.description}
                </Typography>
              </div>
            )}
          </Box>
          <Button
            size="large"
            color="inherit"
            startIcon={<SettingsIcon />}
            onClick={() => setSettingsOpen(true)}
          >
            Settings
          </Button>
        </Box>
        <Divider sx={{ my: 2, borderStyle: "dashed" }} />

        {true && (
          <Box
            ref={containerRef}
            sx={{
              display: "flex",
              justifyContent: "center",
              overflow: "hidden",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              width: "90%",
              margin: "auto",
              mt: 5,
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",

                    height: "100%",
                  }}
                >
                  <Slide
                    container={containerRef.current}
                    direction={slideIn ? "right" : "left"}
                    in={slideIn && !loading}
                    mountOnEnter
                    unmountOnExit
                  >
                    <div>
                      {currentQuiz?.questions && !submitted && (
                        <Flashcard
                          flashcard={currentQuiz?.questions[currentIndex]}
                          flipped={flipped}
                          setFlipped={setFlipped}
                          repeatIncorrect={repeatIncorrect}
                          numIncorrect={incorrectQuestions?.length}
                          showAnswerFirst={showAnswerFirst}
                          timerEnabled={timerEnabled}
                          timerDuration={timerDuration}
                          submitted={submitted}
                          incorrect={incorrectQuestions.length}
                          correct={score}
                        />
                      )}
                    </div>
                  </Slide>
                </Box>
                {(!submitted && flipped && !showAnswerFirst) ||
                (!submitted && !flipped && showAnswerFirst) ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "auto",
                      width: 500,
                    }}
                  >
                    <Grow in timeout={600}>
                      <Button
                        color="error"
                        startIcon={<XCircle />}
                        onClick={() => handleNext(false)}
                      >
                        Incorrect
                      </Button>
                    </Grow>
                    <Grow in timeout={600}>
                      <Button
                        startIcon={<Check />}
                        onClick={() => handleNext(true)}
                      >
                        Correct
                      </Button>
                    </Grow>
                  </Box>
                ) : null}
              </>
            )}
          </Box>
        )}
      </Box>
      {repeatIncorrect && incorrectQuestions?.length > 0 ? (
        <>
          <Typography variant="h6" color="error" align="center" sx={{ my: 2 }}>
            Repeating incorrect questions: {currentIncorrectIndex + 1}/
            {incorrectQuestions.length}
          </Typography>
          <Box
            ref={containerRef}
            sx={{
              display: "flex",
              justifyContent: "center",
              overflow: "hidden",
              flexDirection: "column",
              alignItems: "center",
              width: "90%",
              margin: "auto",
              marginTop: "3%",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Slide
                container={containerRef.current}
                direction={slideIn ? "right" : "left"}
                in={slideIn && !loading}
                mountOnEnter
                unmountOnExit
              >
                <div>
                  <Flashcard
                    flashcard={incorrectQuestions[currentIncorrectIndex]}
                    repeatIncorrect={repeatIncorrect}
                    numIncorrect={incorrectQuestions?.length}
                    flipped={flipped}
                    setFlipped={setFlipped}
                    showAnswerFirst={showAnswerFirst}
                    timerEnabled={timerEnabled}
                    timerDuration={timerDuration}
                    submitted={submitted}
                    incorrect={incorrectQuestions.length}
                    correct={score}
                  />
                </div>
              </Slide>
            </Box>
            {(repeatIncorrect && flipped && !showAnswerFirst) ||
            (repeatIncorrect && !flipped && showAnswerFirst) ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "auto",
                  width: 500,
                }}
              >
                <Grow in timeout={600}>
                  <Button
                    color="error"
                    startIcon={<XCircle />}
                    onClick={() => handleRepeatNext()}
                  >
                    Incorrect
                  </Button>
                </Grow>
                <Grow in timeout={600}>
                  <Button
                    startIcon={<Check />}
                    onClick={() => handleRepeatNext()}
                  >
                    Correct
                  </Button>
                </Grow>
              </Box>
            ) : null}
          </Box>
        </>
      ) : submitted && repeatIncorrect && incorrectQuestions?.length === 0 ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Slide
            container={containerRef.current}
            direction={slideIn ? "right" : "left"}
            in={slideIn && !loading}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <Flashcard
                flashcard={incorrectQuestions[currentIncorrectIndex]}
                repeatIncorrect={repeatIncorrect}
                numIncorrect={incorrectQuestions?.length}
                flipped={flipped}
                setFlipped={setFlipped}
                showAnswerFirst={showAnswerFirst}
                timerEnabled={timerEnabled}
                timerDuration={timerDuration}
                submitted={submitted}
                incorrect={incorrectQuestions.length}
                correct={score}
              />
            </div>
          </Slide>
        </Box>
      ) : (
        submitted &&
        !repeatIncorrect && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Slide
              container={containerRef.current}
              direction={slideIn ? "right" : "left"}
              in={slideIn && !loading}
              mountOnEnter
              unmountOnExit
            >
              <div>
                <Flashcard
                  flashcard={incorrectQuestions[currentIncorrectIndex]}
                  repeatIncorrect={repeatIncorrect}
                  numIncorrect={incorrectQuestions?.length}
                  flipped={flipped}
                  setFlipped={setFlipped}
                  showAnswerFirst={showAnswerFirst}
                  timerEnabled={timerEnabled}
                  timerDuration={timerDuration}
                  submitted={submitted}
                  incorrect={incorrectQuestions.length}
                  correct={score}
                />
              </div>
            </Slide>
          </Box>
        )
      )}
      <Settings
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        shuffle={shuffle}
        setShuffle={setShuffle}
        showAnswerFirst={showAnswerFirst}
        setShowAnswerFirst={setShowAnswerFirst}
        timerEnabled={timerEnabled}
        setTimerEnabled={setTimerEnabled}
        timerDuration={timerDuration}
        setTimerDuration={setTimerDuration}
        repeatIncorrect={repeatIncorrect}
        setRepeatIncorrect={setRepeatIncorrect}
      />
    </>
  );
}
