import React from 'react';
import {
  AutoAwesome,
  CheckCircleOutlineRounded,
  HighlightOffOutlined,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  Fade,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Clock } from "lucide-react";
import ConfettiExplosion from "react-confetti-explosion";

const QuizContent = ({ currentQuiz, quizState, totalSeconds, handleAnswerClick }) => {
  const { title, description, questions, subject } = currentQuiz;
  const currentQuestion = questions[quizState.currentQuestionIndex];

  return (
    <Card
      sx={{
        width: "70%",
        margin: "auto",
        padding: 5,
        marginTop: "20px",
        borderRadius: 4,
        boxShadow:
          "rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 8px 20px -4px",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <AutoAwesome
          sx={{
            fontSize: 20,
            marginRight: 1,
            color: "#00a76f",
            fontWeight: 800,
          }}
        />
        <Typography
          variant="body2"
          sx={{
            color: "#00a76f",
            fontWeight: 800,
          }}
        >
          Generated for {subject.toUpperCase()}
        </Typography>
      </Box>
      <Typography variant="h4" sx={{ mt: 1 }}>
        {title}
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mt: 0.5 }}>
        {description}
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Fade in={!quizState.zoomOut} timeout={500}>
        <div style={{ marginBottom: "20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack>
              <Typography variant="body2" color="text.secondary">
                Question {quizState.currentQuestionIndex + 1}/{questions.length}
              </Typography>
              <Typography variant="h5" sx={{ mb: 2 }}>
                {currentQuestion.question}
              </Typography>
            </Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Clock sx={{ mr: 2 }} />
              &nbsp;
              {totalSeconds}
            </Box>
          </Box>
          <Grid container spacing={2}>
            {currentQuestion.options.map((option, index) => {
              const isCorrect =
                quizState.clickedButtonIndex === index
                  ? quizState.clickedButtonIndex === index &&
                    currentQuestion.answer === option
                  : "";

              return (
                <Grid key={index} item xs={6}>
                  <Button
                    variant="outlined"
                    size="large"
                    startIcon={
                      isCorrect ? (
                        <CheckCircleOutlineRounded fontSize="large" />
                      ) : quizState.clickedButtonIndex === index && !isCorrect ? (
                        <HighlightOffOutlined fontSize="large" />
                      ) : (
                        <RadioButtonUnchecked fontSize="large" />
                      )
                    }
                    sx={{
                      pointerEvents: quizState.clickedButtonIndex === index && "none",
                      marginBottom: "10px",
                      width: "100%",
                      minHeight: "100%",
                      justifyContent: "flex-start",
                      fontWeight: 600,
                      borderColor:
                        quizState.clickedButtonIndex === index
                          ? isCorrect
                            ? "success.main"
                            : "error.main"
                          : "none",
                      backgroundColor:
                        quizState.clickedButtonIndex === index
                          ? isCorrect
                            ? "rgba(80, 193, 60, 0.08)"
                            : "rgba(255, 86, 48, 0.08)"
                          : "inherit",
                      color:
                        quizState.clickedButtonIndex === index
                          ? isCorrect
                            ? "success.main"
                            : "error.main"
                          : "inherit",
                      "&:hover": {
                        backgroundColor:
                          quizState.clickedButtonIndex === index
                            ? isCorrect
                              ? "rgba(80, 193, 60, 0.16)"
                              : "rgba(255, 72, 66, 0.16)"
                            : "none",
                        borderColor:
                          quizState.clickedButtonIndex === index
                            ? isCorrect
                              ? "success.main"
                              : "error.main"
                            : "none",
                      },
                    }}
                    onClick={() => handleAnswerClick(option, index)}
                  >
                    {quizState.clickedButtonIndex === index && isCorrect && (
                      <ConfettiExplosion 
                        force={0.4}
                        duration={2200}
                        particleCount={30}
                        width={400}
                      />
                    )}
                    <Box width="85%">{option}</Box>
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Fade>
    </Card>
  );
};

export default QuizContent;
